import { useState } from "react";
//styles
import styles from "./modalDetailCredit.module.css";
//libraries
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
//utils
import cashFormatter from "utils/cashFormatter";
import { dateFormater, reducedDateFormat } from "utils/dateFormater";
// Components

const ModalDetailCredit = (props) => {
  let { selectedCredit, setModalDetailCredit, isModalDetailCredit } = props;

  const date = new Date();
  const today = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')

  let totalToPay = 0
  const hasPastDue = selectedCredit.payments.find(payment => payment.has_past_due);
  selectedCredit.payments.forEach(function (payment) { if (!payment.used) totalToPay += payment.total_value_with_past_due })

  return (
    <Modal
      centered
      size='lg'
      show={isModalDetailCredit}
      className={styles.principal_content}
      onHide={() => setModalDetailCredit(false)}
    >
      <Modal.Body className={styles.modal_body}>
        <FaTimes
          className={styles.close_icon}
          onClick={() => setModalDetailCredit(false)}
        />
        <div className={styles.principal_container}>
          <p className={styles.title}>Código de crédito {selectedCredit.id_credit}</p>
          <p
            className={styles.paid}
            style={{
              color:
                selectedCredit.state === "FIN"
                  ? "var(--COLOR_SUCCESS)"
                  : "var(--MEDIUM_BLUE)",
            }}
          >
            {selectedCredit.state === "FIN" ? "Pagado" : "Vigente"}
          </p>
          {/* installments */}
          <div className={styles.container_installments}>
            <div className={styles.container_row}>
              <p className={styles.installments_title_table}>Fecha de pago</p>
              <p className={styles.installments_title_table}>Valor cuota</p>
              <p className={styles.installments_title_table}>Días de mora</p>
              <p className={styles.installments_title_table}>Valor de la mora</p>
              <p className={styles.installments_title_table}>Valor a pagar</p>
              <p className={styles.installments_title_table}>Estado</p>
            </div>
            {selectedCredit.payments.map((installment) => {
              const defColorText = () => {
                let colorText = ''
                if (installment.used) {
                  colorText = 'var(--COLOR_SUCCESS)'
                } else {
                  if (installment.payment_date_expected < today) {
                    colorText = 'var(--COLOR_DANGER)'
                  } else {
                    colorText = 'var(--MEDIUM_BLUE)'
                  }
                  return colorText
                }
              }
              return (
                <div
                  key={installment.id_payment}
                  className={styles.container_row}
                >
                  <div className={styles.installments_value_table}>
                    <p className={styles.value} style={{ color: defColorText() }}>
                      {window.innerWidth > 768
                        ? dateFormater(installment.payment_date_expected, false)
                        : reducedDateFormat(installment.payment_date_expected, false)}
                    </p>
                  </div>
                  <div className={styles.installments_value_table}>
                    <p className={styles.value} style={{ color: defColorText() }} >
                      {cashFormatter(installment.value)}
                    </p>
                  </div>
                  <div className={styles.installments_value_table}>
                    <p className={styles.value} style={{ color: defColorText() }} >
                      {installment.days_past_due}
                    </p>
                  </div>
                  <div className={styles.installments_value_table}>
                    <p className={styles.value} style={{ color: defColorText() }} >
                      {cashFormatter(installment.value_past_due)}
                    </p>
                  </div>
                  <div className={styles.installments_value_table}>
                    <p className={styles.value} style={{ color: defColorText() }} >
                      {cashFormatter(installment.total_value_with_past_due)}
                    </p>
                  </div>
                  <div className={styles.installments_value_table}>
                    <p className={styles.value} style={{ color: defColorText() }}>
                      {installment.used
                        ? 'Pagado'
                        : installment.payment_date_expected < today
                          ? 'Mora'
                          : 'Al día'
                      }
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          {selectedCredit.state !== 'FIN' &&
            <div className={styles.valuesTotal}>
              {
                +selectedCredit.collection_management > 0 &&
                <p className={styles.totalToPay}>Gestión cobranza: <span>{cashFormatter(selectedCredit.collection_management)}</span></p>
              }
              {hasPastDue &&
                <p className={styles.totalToPay}>Valor con mora: <span>{cashFormatter(totalToPay)}</span></p>
              }
              <p className={styles.grandTotal}>Total: <span style={{ color: hasPastDue ? 'var(--COLOR_DANGER)' : 'var(--DARK_BLUE)' }}>{cashFormatter(totalToPay + +selectedCredit.collection_management)}</span></p>
            </div>}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailCredit;
