import React from "react";
// Libraries
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// Styles
import styles from "./ModalRejected.module.css";
// Images
import imagen from "images/Credit/Information/credito_rechazado.png";
// components
import TheButton from "components/TheButton/TheButton";
// utils
import settings from "utils/config";
import { inicio } from "utils/pathName";
import { cleanUpDataSignUp } from "utils/clearDataSignUp";
// redux
import { setInfoAuth } from "redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";

export default function ModalRejected(props) {
  const history = useHistory();
  let { open, setOpen, isItRejectedForSignUp } = props;
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  function goBackCleanUpDataSignUp() {
    cleanUpDataSignUp(dispatch, userDataSignUp, userReducer)
    dispatch(
      setInfoAuth({
        token_access: userReducer[settings.token_access],
        token_refresh: userReducer[settings.token_refresh],
        ...userReducer.info_auth,
        cellphone: "",
      })
    );
    console.log('userReducer', userReducer)
    history.replace(inicio);
  }

  const ModalRejectedCredit = (
    <div className={styles.alignModal}>
      <div className={styles.modalContainer}>
        <img
          className={styles.modalIMG}
          src={imagen}
          alt="Respuesta de crédito"
        />

        <h2 className={styles.haveProblem}>"Houston... tenemos un problema"</h2>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <h6 style={{ marginBottom: "1rem" }}>
            En este momento no podemos brindarte el crédito que necesitas
          </h6>
          <h5 className={styles.lightBlueText}>¡Pero no te desanimes!</h5>
          <h6 style={{ marginTop: "1rem" }}>
            Este tipo de situaciones suelen suceder durante los estudios de
            crédito
          </h6>
          <br />
          <h6>
            Te recomendamos que revises nuevamente la plataforma
            <br />
            en un mes, te aseguramos que tu situación crediticia
            <br />
            será mejor
          </h6>
          <br />
          <div className={styles.container_button}>
            <TheButton
              nameText="Volver al inicio"
              background="var(--MEDIUM_BLUE)"
              GoClick={() => {
                if (isItRejectedForSignUp) {
                  goBackCleanUpDataSignUp();
                } else {
                  history.replace(inicio)
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal centered show={open} onHide={() => setOpen(true)} size="lg">
      {ModalRejectedCredit}
    </Modal>
  );
}
