import React, { useState, useEffect } from "react";
// Styles
import styles from "./InfoValidate.module.css";
// Components
import Header from "components/Header";
import Loader from "components/loader/Loader";
import ArrowBack from "components/arrowBack/ArrowBack";
import InfoValidate from "./components/Info/InfoValidate";
import InfoCredito from "components/InfoCredito/InfoCredito";
import FormLinksTyC from "./components/formLinksTyC/FormLinksTyC";
import ModalRejected from "components/ModalRejected/ModalRejected";
import ModalValidate from "components/ModalValidate/ModalValidate";
import { toast_custom } from "components/toast_custom/toast_custom";
import InfoBasicWork from "./components/InfoBasicWork/InfoBasicWork";
// Libraries
import { useHistory } from "react-router-dom";
import TheButton from "../../../../components/TheButton/TheButton";
import ModalEditInfoWork from "../../../../components/modalEditInfoWork/ModalEditInfoWork";
// Utils
import settings from "utils/config";
import simulatorCredit from "utils/creditSimulator";
import { reducedDateFormat } from "utils/dateFormater";
import { creditApproved, dataValidate, newCredit } from "utils/pathName";
// API
import { postSignUp } from "Api/signUp/signUp";
import { postGetDataUser } from "Api/profile/profile";
import { postApplyForCredit } from "Api/credit/applyForCredit";
import { apiRefreshToken } from "utils/Hooks/useApiRefreshToken";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { setInfoAuth, setInfoCredit } from "redux/Actions/Actions";

export default function Information_validation() {
  const [loading, setLoading] = useState(false);
  const [isModalEditWork, setModalEditWork] = useState(false);
  const [openModalReject, setOpenModalReject] = useState(false);
  const [dataUserAndWork, setDataUserAndWork] = useState(undefined);
  const [isItRejectedForSignUp, setIsItRejectedForSignUp] = useState(false);
  const [openModalValidateInfo, setOpenModalValidateInfo] = useState(false);

  let dispatch = useDispatch();
  const history = useHistory();
  const path = window.location.pathname
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  const [checks, setChecks] = useState({
    isTyC: false,
    centralRisk: false,
    dataTreatment: false,
  });

  useEffect(() => {
    if (path !== dataValidate) {
      apiGetDataUser()
    }
  }, [])

  function handleChange(e) {
    setChecks({
      ...checks,
      [e.target.name]: e.target.checked,
    });
  }

  const apiGetDataUser = (token_access) => {
    let token = token_access || userReducer[settings.token_access];
    let dataToSend = {};

    setLoading(true);
    postGetDataUser(dataToSend, token)
      .then((response) => {
        setLoading(false);
        console.log(`response data profile`, response);
        let { data, errored } = response;
        if (errored) {
          if (data.code === "token_not_valid") {
            apiRefreshToken(dispatch, userReducer, history, apiGetDataUser);
            return
          }
          alert('error')
        } else {
          let user = data.user
          let work = data.working_information
          let userComplete = { ...user, ...work }
          setDataUserAndWork(userComplete)
        }
      })
      .catch((err) => {
        console.log("err catch: ", err);
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  const apiPostSignUp = () => {
    if (!checks.isTyC || !checks.dataTreatment) {
      toast_custom(
        "Debes aceptar los terminos y condiciones para poder avanzar",
        "error",
        4000,
        "top-right"
      );
      return
    }
    let cellphoneToSend = `+57${userReducer.info_auth.cellphone}`;
    let password = userDataSignUp.password;
    let dataToSend = {
      dni: userDataSignUp.dni,
      email: userDataSignUp.email,
      cellphone: cellphoneToSend,
      address: userDataSignUp.address,
      genre: userDataSignUp.genre.value,
      id_city: userDataSignUp.city.value,
      last_name: userDataSignUp.last_name,
      first_name: userDataSignUp.first_name,
      neighborhood: userDataSignUp.district,
      installments: userReducer.info_credit.fee,
      acquired_debt: userReducer.info_credit.amount,
      monthly_income: +userDataSignUp.monthly_income,
      // type_document: userDataSignUp.typeDocument.code,
      birthday: reducedDateFormat(userDataSignUp.birthday),
      number_people_in_charge: +userDataSignUp.number_people_in_charge,
      // id_country_dni_expedition: +userDataSignUp.countryExpedition.code,
      date_dni_expedition: reducedDateFormat(
        userDataSignUp.date_dni_expedition
      ),
      type_occupation: userDataSignUp.type_occupation,

      // Ciudad de expedición del documento
      id_city_dni_expedition: userDataSignUp.city_issuance_dni.value,
    };

    if (userDataSignUp.type_occupation === 'employee') {
      dataToSend.company = userDataSignUp.company;
      dataToSend.id_company = userDataSignUp.id_company;
    } else {
      dataToSend.code_activity = userDataSignUp.job.code;
    }

    setOpenModalValidateInfo(true);
    postSignUp(dataToSend, password, "allied/")
      .then((response) => {
        setOpenModalValidateInfo(false);
        console.log(`response sign up`, response);
        let { data, errored } = response;
        if (errored) {
          switch (data.err_code) {
            case "USER_EMAIL_EXISTS":
              toast_custom(
                "Este email ya se encuentra registrado",
                "error",
                4000,
                "top-right"
              );
              break;
            case "USER_DNI_EXISTS":
              toast_custom(
                "Numero de identificación ya se encuentra registrado",
                "error",
                4000,
                "top-right"
              );
              break;
            default:
              console.log("err api: ", errored);
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
        } else {
          dispatch(
            setInfoAuth({
              token_access: data.tokens.access,
              token_refresh: data.tokens.refresh,
              ...userReducer.info_auth,
              cellphone: ""
            })
          );
          let isChangeCredit = false
          if (data.datacredito_information.operation === 'NO PRESTAMO') {
            setIsItRejectedForSignUp(true)
            setOpenModalReject(true);
          } else {
            if (+userReducer.info_credit.fee !== +data.datacredito_information.installments || +userReducer.info_credit.amount !== +data.datacredito_information.acquired_debt) {
              let responseSimulatorCredit = simulatorCredit(
                +data.datacredito_information.installments,
                parseFloat(userReducer.info_credit.vat),
                parseFloat(userReducer.info_credit.endorsement),
                parseFloat(userReducer.info_credit.central_risk),
                parseFloat(userReducer.info_credit.interest_rate),
                +data.datacredito_information.acquired_debt
              );
              dispatch(
                setInfoCredit({
                  ...userReducer.info_credit,
                  id_credit: data.id_credit,
                  fee: data.datacredito_information.installments,
                  monthlyCost: responseSimulatorCredit.monthlyCost,
                  amount: data.datacredito_information.acquired_debt,
                })
              );
              isChangeCredit = true
            } else {
              dispatch(
                setInfoCredit({
                  ...userReducer.info_credit,
                  id_credit: data.id_credit,
                })
              );
            }
            history.replace({
              pathname: creditApproved,
              state: { changeCredit: isChangeCredit }
            })
          }
        }
      })
      .catch((err) => {
        console.log("err catch: ", err);
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  function apiPostApplyForCredit(token_access) {
    let token = token_access || userReducer[settings.token_access];
    let dataToSend = {
      installments: userReducer.info_credit.fee,
      acquired_debt: userReducer.info_credit.amount,
      monthly_income: +dataUserAndWork.monthly_income,
      type_occupation: dataUserAndWork.type_occupation,
      number_people_in_charge: +dataUserAndWork.number_people_in_charge,
    };
    if (dataUserAndWork.type_occupation === 'employee') {
      dataToSend.company = dataUserAndWork.company;
    } else {
      dataToSend.code_activity = dataUserAndWork.activity.code;
    }

    console.log('dataToSend', dataToSend)

    setOpenModalValidateInfo(true);
    postApplyForCredit(dataToSend, token)
      .then((response) => {
        setOpenModalValidateInfo(false);
        console.log(`response apply for credit`, response);
        let { data, errored } = response;
        if (errored) {
          switch (data.err_code) {
            case "token_not_valid":
              apiRefreshToken(dispatch, userReducer, history, apiPostApplyForCredit);
              break;
            case "object_not_found":
              console.log("No credit for mora");
              toast_custom(
                "Comunícate con nosotros ya que en estos momentos la plataforma no puede ofrecerte un nuevo crédito",
                "warning",
                4000,
                "top-right"
              );
              break;
            default:
              console.log("err api: ", errored);
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
        } else {
          let isChangeCredit = false
          if (data.datacredito_information.operation === 'NO PRESTAMO') {
            setOpenModalReject(true);
          } else {
            if (+userReducer.info_credit.fee !== +data.datacredito_information.installments || +userReducer.info_credit.amount !== +data.datacredito_information.acquired_debt) {
              let responseSimulatorCredit = simulatorCredit(
                +data.datacredito_information.installments,
                parseFloat(userReducer.info_credit.vat),
                parseFloat(userReducer.info_credit.endorsement),
                parseFloat(userReducer.info_credit.central_risk),
                parseFloat(userReducer.info_credit.interest_rate),
                +data.datacredito_information.acquired_debt
              );
              dispatch(
                setInfoCredit({
                  ...userReducer.info_credit,
                  id_credit: data.id_credit,
                  fee: data.datacredito_information.installments,
                  monthlyCost: responseSimulatorCredit.monthlyCost,
                  amount: data.datacredito_information.acquired_debt,
                })
              );
              isChangeCredit = true
            } else {
              dispatch(
                setInfoCredit({
                  ...userReducer.info_credit,
                  id_credit: data.id_credit,
                })
              );
            }
            history.replace({
              pathname: creditApproved,
              state: { changeCredit: isChangeCredit, trackinLogin: true }
            })
          }
        }
      })
      .catch((err) => {
        console.log("err catch: ", err);
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  if (!dataUserAndWork && path !== dataValidate) {
    return <>
      <Loader loading={loading} />
      {path !== dataValidate && <Header />}
    </>
  } else {
    return (
      <>
        <Loader loading={loading} />
        <ModalValidate
          open={openModalValidateInfo}
          setOpen={setOpenModalValidateInfo}
        />
        {path !== dataValidate &&
          <ModalEditInfoWork
            dataUserAndWork={dataUserAndWork}
            isModalEditWork={isModalEditWork}
            setModalEditWork={setModalEditWork}
            setDataUserAndWork={setDataUserAndWork}
          />
        }
        <ModalRejected open={openModalReject} setOpen={setOpenModalReject} isItRejectedForSignUp={isItRejectedForSignUp} />
        {path !== dataValidate && <Header />}
        <div className={styles.Container}>
          <ArrowBack
            showLogo={false}
            backText="Volver"
            onClick={() => history.goBack()}
          />
          <InfoValidate />
          <InfoCredito />
          <InfoBasicWork
            dataUserAndWork={dataUserAndWork}
            setModalEditWork={setModalEditWork}
          />
          {path !== newCredit &&
            <FormLinksTyC handleChange={handleChange} checks={checks} />
          }
          <div style={{ margin: "2rem 0" }}>
            <TheButton
              background="var(--MEDIUM_BLUE)"
              GoClick={() => {
                if (path === newCredit) {
                  console.log('new credit')
                  apiPostApplyForCredit()
                } else {
                  apiPostSignUp()
                }
              }}
              nameText="Solicitar aprobación de crédito"
            />
          </div>
        </div>
      </>
    );
  }
}
